import React from 'react'
import Hero from '../components/Hero'
import FetchData from '../components/FetchData'
const Home = () => {
  return (
    <><Hero/>
    <FetchData/>
    
    </>
  )
}

export default Home
