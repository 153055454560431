import React from 'react'

const Hero = () => {
  return (
    <div className="container-fluid bg-dark text-white d-flex justify-content-center align-items-center flex-column" style={{height:"50vh"}}>
     <h1 style={{fontSize:"4rem" ,color:"orange"}}>NEWS Hub</h1>
     <h5>THE WEBSITE BUILD WITH NEWS API</h5>
    </div>
  );
};

export default Hero
