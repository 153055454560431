import React from 'react'

const Footer = () => {
  return (
    <div className='container-fluid d-flex justify-content-center align-items-center bg-black text-white  p-3'>
     <h5>Developed by <i> team</i> </h5> 
    </div>
  )
}

export default Footer

